<template>
	<v-fade-transition mode="out-in">
		<router-view />
	</v-fade-transition>
</template>

<script>
export default {
	name: "App",
	metaInfo: {
		title: "Sustainable Future Challenge Official Website | 可持续未来挑战赛官方网站",
		/* titleTemplate: "%s | Sustainable Future Challenge", */
		htmlAttrs: { lang: "zh-CN" },
		meta: [
			{ charset: "utf-8" },
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
			{
				name: "description",
				content: "Sustainable Future Challenge Official Website | 可持续未来挑战赛官方网站",
			},
			{
				name: "keywords",
				content: "Sustainable Future Challenge, 可持续未来挑战赛官方网站, 太阳能, 绿色建筑, 可持续能源",
			},
			{
				name: "author",
				content: "Organizing Committee of Sustainable Future Challenge 可持续未来挑战赛组委会",
			},
			{
				property: "og:title",
				content: "Sustainable Future Challenge Official Website | 可持续未来挑战赛官方网站",
			},
		],
	},
};
</script>
